import React from 'react'
import PropTypes from 'prop-types'

import map from '../images/map.png'
import pic01 from '../images/pic01.jpg'
import pic02 from '../images/pic02.jpg'
import pic03 from '../images/pic03.jpg'

class Main extends React.Component {
  render() {

    let close = <div className="close" onClick={() => {this.props.onCloseArticle()}}></div>

    return (
      <div ref={this.props.setWrapperRef} id="main" style={this.props.timeout ? {display: 'flex'} : {display: 'none'}}>

        <article id="intro" className={`${this.props.article === 'intro' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">What</h2>
          <span className="image main"><img src={pic01} alt="" /></span>
          <h2 className="center">Jax Hack 2019</h2>
          <h3>hack·a·thon
          /ˈhakəˌTHän/
          </h3>
          <h4>A hackathon is a gathering where programmers and designers collaboratively code in an extreme manner over a short period of time.</h4>
          <h4>It's also a lot of fun.</h4>
          <br/>
          <h3>AGENDA</h3>
          <p>7:00-8:00am: Arrive, check-in, setup your workspace, and grab some breakfast (provided)</p>
          <p>8:00-8:15am: Rules and opening ceremony</p>
          <p>8:15-6:15pm: Hack.</p>
          <p>6:15-7:00pm: Hackers demo and pitch their product</p>
          <p>7:00-7:30pm: Votes counted & Winners announced</p>
          <p>7:30pm-????: Afterparty</p>
          <p>NOTE: Meals and snacks will be provided throughout the day.</p>

          <p>In one long, caffeine-fueled day, 10 teams will go from back-of-a-napkin sketch to demoing their software in front of an audience and a panel of local tech judges. Prizes will be had for all, but only one team will leave with the grand prize and a year-full of recognition.</p>
          <p>All teams and public attendees will be able to participate in the presentation and well-deserved afterparty.</p>
          <p>We are looking for volunteers to help out for some of all of the hackathon. We will feed you. Contact us if you're interested to help make this hackathon amazing!</p>
          {close}
        </article>

        <article id="whenwhere" className={`${this.props.article === 'whenwhere' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">When & Where</h2>
          <span className="image main"><img src={map} alt="" /></span>
          {/* <p>insert awesome interactive map</p> */}
          <p>TBD, but somewhere awesome in Jacksonville!</p>
          {close}
        </article>

        <article id="team" className={`${this.props.article === 'team' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Team Signup</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <h3> <a href="https://erik106.typeform.com/to/S6gBtZ" target="_blank">Fill out the form here to signup!</a></h3>
          <li>Signup Deadline: July 15, 2019</li>
          <li>Teams:  2 - 6 people</li>
          <li>Date/Time: August 17, 2019, 07:00am - 07:30pm ( + afterparty )</li>
          <li>Winning team will get sweet TBD prizes :tada: 🎉</li>
          {/* <p>Got your team and idea ready to go?</p> */}
          <br/> 
          <br/>
          <h4>Rules</h4>
          <li>Teams are strongly encouraged to come up with at least a rough idea of what they want to build beforehand. But, actually <u>working on any code beforehand is off limits</u>.</li>
          <li>Don't be mean. 🤷‍♀️</li>
          <br/>
          
          <h4>Details you probably won't read</h4>
          <p>After The July 15th deadline, we will have a panel of judges assess the applicants. The judges will select the top 10 ideas that sound the most viable for the hackathon and invite them to attend. Other applicants will be waitlisted. Teams will have 1 week to confirm their invitation or lose their spot. Waitlisted teams will be notified ASAP when a spot opens up.</p>
          
          
          {close}
        </article>

        <article id="sponsor" className={`${this.props.article === 'sponsor' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Sponsors</h2>
          <span className="image main"><img src={pic03} alt="" /></span>
          <p>Here are our awesome sponsors:</p>
          <p> </p>
          <p>Interested in sponsoring the event? <a href="mailto:jaxhack@unhype.me">Please reach out to us!</a> From big to small, all sponsorships are welcome!</p>
          <p>Contact us <a href="mailto:jaxhack@unhype.me">via email (jaxhack@unhype.me)</a> or via <a href="https://twitter.com/jaxhack"> Twitter @jaxhack</a>.</p>
          {close}
        </article>

        <article id="contact" className={`${this.props.article === 'contact' ? 'active' : ''} ${this.props.articleTimeout ? 'timeout' : ''}`} style={{display:'none'}}>
          <h2 className="major">Contact</h2>
          <p>Contact us <a href="mailto:jaxhack@unhype.me">via email (jaxhack@unhype.me)</a> or via <a href="https://twitter.com/jaxhack"> Twitter @jaxhack</a>.</p>
          {/* <form method="post" action="#">
            <div className="field half first">
              <label htmlFor="name">Name</label>
              <input type="text" name="name" id="name" />
            </div>
            <div className="field half">
              <label htmlFor="email">Email</label>
              <input type="text" name="email" id="email" />
            </div>
            <div className="field">
              <label htmlFor="message">Message</label>
              <textarea name="message" id="message" rows="4"></textarea>
            </div>
            <ul className="actions">
              <li><input type="submit" value="Send Message" className="special" /></li>
              <li><input type="reset" value="Reset" /></li>
            </ul>
          </form> */}
          <ul className="icons">
            <li><a href="https://twitter.com/jaxhack" className="icon fa-twitter"><span className="label">Twitter</span></a></li>
            {/* <li><a href="#" className="icon fa-facebook"><span className="label">Facebook</span></a></li> */}
            {/* <li><a href="#" className="icon fa-instagram"><span className="label">Instagram</span></a></li> */}
            <li><a href="https://github.com/esthor" className="icon fa-github"><span className="label">GitHub</span></a></li>
          </ul>
          {close}
        </article>

      </div>
    )
  }
}

Main.propTypes = {
  route: PropTypes.object,
  article: PropTypes.string,
  articleTimeout: PropTypes.bool,
  onCloseArticle: PropTypes.func,
  timeout: PropTypes.bool,
  setWrapperRef: PropTypes.func.isRequired,
}

export default Main